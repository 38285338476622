<template>
  <b-container class="mt-4">
    <b-row>
      <b-col class="d-flex align-items-center">
        <BackButton></BackButton>
        <h5 class="ml-2 mb-0">
          {{ translations.back_list }}
        </h5>
        <b-button
          id="export-837-file"
          class="mr-0 ml-auto"
          @click="export837File">
          {{ translations.export_837_file }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-card class="border-0 w-100" :header="translationsFinance.claims_batches.details.page_title" body-class="px-0"
                header-class="border-0">
          <b-col>
            <b-row cols="4" class="mb-5">
              <b-col>
                <b>{{ translations.id }}</b>
                <p>{{ details.id }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.client_name }}</b>
                <p>{{ deepValue(details, 'statement.client_name') }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.total_balance }}</b>
                <p>{{ formatValue(details.total_balance) }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.status }}</b>
                <p>{{ details.status }}</p>
              </b-col>
            </b-row>
            <b-row cols="4" class="mb-5">
              <b-col>
                <b>{{ translations.statement_id }}</b>
                <p>{{ deepValue(details, 'statement.id') }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.payer_name }}</b>
                <p>{{ deepValue(details, 'statement.statement_configuration.payer.payer_name') }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.payer_id }}</b>
                <p>{{ deepValue(details, 'statement.statement_configuration.payer.payer_external_id') }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.place_of_service }}</b>
                <p>{{
                    placeOfServiceLabel(deepValue(details, 'statement.statement_configuration.payer.place_of_service'))
                  }}</p>
              </b-col>
            </b-row>
            <b-row cols="4" class="mb-5">
              <b-col>
                <b>{{ translations.provider_name }}</b>
                <p>{{ deepValue(details, 'statement.statement_configuration.billing_provider.name') }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.provider_npi }}</b>
                <p>{{ deepValue(details, 'statement.statement_configuration.billing_provider.npi') }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.name }}</b>
                <p>{{ details.name }}</p>
              </b-col>
              <b-col>
                <b>{{ translations.created_at }}</b>
                <p>{{ formatDatetime(details.created_at) }}</p>
              </b-col>
            </b-row>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
    <b-card class="border-0 w-100" :header="translationsFinance.claims_batches.details.claims.section_title"
            header-class="border-0">
      <table class="table" data-id="claims-table">
        <thead>
        <tr>
          <th>{{ translations.claims.unique_key }}</th>
          <th>{{ translations.claims.program_id }}</th>
          <th>{{ translations.claims.member_full_name }}</th>
          <th>{{ translations.claims.value }}</th>
          <th>{{ translations.claims.paid_value }}</th>
          <th>{{ translations.claims.status }}</th>
          <th>{{ translations.claims.status_date }}</th>
          <th class="btn-expand-col"/>
        </tr>
        </thead>
        <tbody>
        <template v-for="(claim, claimIndex) in claims">
          <tr :key="'tr1-'+claimIndex">
            <td>{{ claim.unique_key }}</td>
            <td>{{ claim.patient_data.patient_id }}</td>
            <td>{{ claim.patient_data.member_full_name }}</td>
            <td>{{ formatValue(claim.value) }}</td>
            <td>{{ formatValue(claim.paid_value) }}</td>
            <td>{{ claim.status }}</td>
            <td>{{ formatDate(claim.status_date) }}</td>
            <td class="btn-expand-col">
              <b-button
                size="sm"
                class="btn-expand"
                @click="toggleDetailsExpanded(claim)">
                <feather v-if="claim.details_expanded" type="chevron-up"/>
                <feather v-else type="chevron-down"/>
              </b-button>
            </td>
          </tr>
          <tr v-if="claim.details_expanded" :key="'tr2-'+claimIndex">
            <td colspan="7" class="expanded-row">
              <b-row cols="4">
                <b-col>
                  <b>{{ translations.claims.member_insurance_number }}</b>
                  <p>{{ formatEmpty(claim.patient_data.member_insurance_number) }}</p>
                </b-col>
                <b-col>
                  <b>{{ translations.claims.subscriber_first_name }}</b>
                  <p>{{ formatEmpty(claim.patient_data.subscriber_first_name) }}</p>
                </b-col>
                <b-col>
                  <b>{{ translations.claims.subscriber_last_name }}</b>
                  <p>{{ formatEmpty(claim.patient_data.subscriber_last_name) }}</p>
                </b-col>
                <b-col>
                  <b>{{ translations.claims.member_relationship }}</b>
                  <p>{{ formatEmpty(claim.patient_data.member_relationship) }}</p>
                </b-col>
                <b-col>
                  <b>{{ translations.claims.program_uuid }}</b>
                  <p>{{ claim.patient_data.program_uuid }}</p>
                </b-col>
                <b-col>
                  <b>{{ translations.claims.account_uuid }}</b>
                  <p>{{ claim.patient_data.account_uuid }}</p>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="6">
                  <span class="d-flex justify-content-center font-weight-bold">
                    {{ translations.transactions_list_title }}</span>
                  <financial-list
                    data-id="transactions-table"
                    v-model="claim.transactions_page"
                    :header-data="TRANSACTIONS_TABLE_HEADERS"
                    :data="claim.transactions"
                    :disabled="doingNetworkRequest"
                    :items-per-page="transactionsPerPage"
                    @page-changed="loadTransactions(claim)"
                    :total="claim.transactions_total"
                    :no-items-message="translationsFinance.transactions.missing_transactions"/>
                </b-col>
                <b-col cols="6">
                  <span class="d-flex justify-content-center font-weight-bold">
                    {{ translations.claim_status_notifications.list_title }}</span>
                  <table class="table table-striped" data-id="status-notifications-table">
                    <tbody v-if="claim.status_notifications.length === 0">
                    <tr>
                      <td class="text-center">{{ translations.claim_status_notifications.no_rows }}
                      </td>
                    </tr>
                    </tbody>
                    <tbody v-else>
                    <tr v-for="(statusChangeNotification, statusChangeNotificationIndex) in claim.status_notifications"
                        :key="statusChangeNotificationIndex">
                      <td class="pb-3">
                        <div>
                          <b>{{ translations.claim_status_notifications.status_date }}:</b>
                          {{ formatDate(statusChangeNotification.status_date) }},
                          <b>{{ translations.claim_status_notifications.processed_at }}:</b>
                          {{ formatDatetime(statusChangeNotification.created_at) }}
                        </div>
                        <div>
                          <b>{{ translations.claim_status_notifications.status }}:</b>
                          {{ translations.claim_status_notifications.old_status }}
                          {{ statusChangeNotification.old_claim_status }},
                          {{ translations.claim_status_notifications.new_status }}
                          {{ statusChangeNotification.new_claim_status }}
                        </div>
                        <div>
                          <b>{{ translations.claim_status_notifications.notes }}:</b>
                          {{ statusChangeNotification.observations }}
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="col-12 d-flex justify-content-center">
                    <b-pagination
                      data-id="status-notifications-pagination"
                      :disabled="doingNetworkRequest"
                      @change="loadStatusNotifications(claim, ...arguments)"
                      v-model="claim.status_notifications_page"
                      :total-rows="claim.status_notifications_total"
                      :per-page="statusNotificationsPerPage"
                      size="md">
                    </b-pagination>
                  </div>
                </b-col>
              </b-row>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
      <div class="col-12 d-flex justify-content-center">
        <b-pagination
          :disabled="doingNetworkRequest"
          @change="changePageClaims"
          v-model="currentClaimsPage"
          :total-rows="totalClaims"
          :per-page="claimsPerPage"
          size="md">
        </b-pagination>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import moment from 'moment';

import BackButton from '@/components/BackButton.vue';
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import translations from '@/translations';
import { formatDate, formatDatetime, formatValue } from '@/helpers/finance';
import utils from '@/scripts/tools/utils';

export default {
  name: 'FinanceClaimsBatchDetails',
  components: {
    BackButton,
    FinancialList,
  },
  created() {
    this.TRANSACTIONS_TABLE_HEADERS = [
      associateHeaderDataItem(this.translationsFinance.transactions.transaction_id, 'id'),
      associateHeaderDataItem(this.translationsFinance.transactions.event_date, 'event_date'),
      associateHeaderDataItem(this.translationsFinance.transactions.therapy, 'therapy_name'),
      associateHeaderDataItem(this.translationsFinance.transactions.transaction_type, 'transaction_type'),
      associateHeaderDataItem(this.translationsFinance.claims_batches.details.transactions.value, 'transaction_value'),
      associateHeaderDataItem(this.translationsFinance.claims_batches.details.transactions.paid_value, 'transaction_paid_value'),
    ];
    this.claimsBatchID = Number(this.$route.params.claimsBatchID);
  },
  data() {
    return {
      translationsFinance: translations.finance,
      translations: translations.finance.claims_batches.details,
      details: {},
      doingNetworkRequest: false,
      claims: [],
      claimsPerPage: 10,
      transactionsPerPage: 5,
      statusNotificationsPerPage: 5,
      totalClaims: 0,
      currentClaimsPage: 1,
    };
  },
  async beforeMount() {
    this.doingNetworkRequest = true;
    await this.loadClaimsBatch();
    await this.loadClaims();
    this.doingNetworkRequest = false;
  },
  methods: {
    formatDate,
    formatDatetime,
    formatValue,
    async changePageClaims(page) {
      this.doingNetworkRequest = true;
      this.currentClaimsPage = page;
      await this.loadClaims();
      this.doingNetworkRequest = false;
    },
    formatEmpty(value) {
      if (!value) {
        return '-';
      }
      return value;
    },
    placeOfServiceLabel(val) {
      switch (val) {
        case '02':
          return translations.finance.payers.place_of_service.telehealth;
        case '10':
          return translations.finance.payers.place_of_service.telehealth_patient_home;
        default:
          return '';
      }
    },
    deepValue(item, path) {
      if (path.indexOf('.') > -1) {
        let value = item;
        const parts = path.split('.');
        for (let i = 0; i < parts.length; ++i) {
          const part = parts[i];
          if (value[part] === undefined || value[part] === null) {
            return '';
          }
          value = value[part];
        }
        return value;
      }

      return item[path] || '';
    },
    async loadClaimsBatch() {
      try {
        const { claimsBatchID } = this;
        const res = await this.$store.dispatch('Financial/getClaimsBatch', { claimsBatchID });
        this.details = res.data.data;
      } catch (err) {
        let errDetail = err;
        if (err.response && err.response.data && err.response.data.error && err.response.data.error.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `${this.translationsFinance.claims_batches.errors.fetch_details_error} ${errDetail}`;
        this.$noty.error(errMsg);
      }
    },
    async loadClaims() {
      const filters = {
        claims_batch_id: this.claimsBatchID,
        offset: (this.currentClaimsPage - 1) * this.claimsPerPage,
        limit: this.claimsPerPage,
        order_descending: true,
        order_by: 'id',
      };
      try {
        const apiClaims = await this.$store.dispatch('Financial/getClaims', filters);

        if (!apiClaims || !apiClaims.data || !apiClaims.data.data || !apiClaims.data.data.claims) {
          this.totalClaims = 0;
          this.claims = [];
        } else {
          apiClaims.data.data.claims.forEach(claim => { // initialize extra properties
            claim.details_expanded = false;
            claim.transactions = [];
            claim.transactions_page = 0;
            claim.transactions_total = 0;

            claim.status_notifications = [];
            claim.status_notifications_page = 0;
            claim.status_notifications_total = 0;
          });
          this.totalClaims = apiClaims.data.data.total;
          this.claims = apiClaims.data.data.claims;
        }
      } catch (err) {
        let errDetail = err;
        if (err.response && err.response.data && err.response.data.error && err.response.data.error.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `${this.translationsFinance.claims_batches.errors.fetch_claims_error} ${errDetail}`;
        this.$noty.error(errMsg);
      }
    },
    async loadTransactions(claim) {
      this.doingNetworkRequest = true;
      const filters = {
        claim_id: claim.id,
        offset: (claim.transactions_page - 1) * this.transactionsPerPage,
        limit: this.transactionsPerPage,
        order_descending: true,
        order_by: 'id',
      };
      const apiTransactions = await this.$store.dispatch('Financial/getTransactions', filters);
      if (!apiTransactions) {
        claim.transactions_total = 0;
        claim.transactions = [];
      } else {
        apiTransactions.transactions.forEach(t => {
          t.transaction_value = formatValue(t.transaction_value);
          t.transaction_paid_value = formatValue(t.transaction_paid_value);
          t.event_date = formatDatetime(t.event_date);
        });
        claim.transactions_total = apiTransactions.total;
        claim.transactions = apiTransactions.transactions;
      }
      this.doingNetworkRequest = false;
    },
    async loadStatusNotifications(claim, pageNr) {
      this.doingNetworkRequest = true;
      claim.status_notifications_page = pageNr;
      const params = {
        offset: (claim.status_notifications_page - 1) * this.statusNotificationsPerPage,
        limit: this.statusNotificationsPerPage,
        order_descending: true,
        order_by: 'id',
      };
      try {
        const apiStatusNotifications = await this.$store.dispatch('Financial/getClaimStatusNotifications', {
          claimId: claim.id,
          params,
        });
        if (!apiStatusNotifications || !apiStatusNotifications.data || !apiStatusNotifications.data.data
          || !apiStatusNotifications.data.data.claim_status_notifications) {
          claim.status_notifications_total = 0;
          claim.status_notifications = [];
        } else {
          claim.status_notifications_total = apiStatusNotifications.data.data.total;
          claim.status_notifications = apiStatusNotifications.data.data.claim_status_notifications;
        }
      } catch (err) {
        let errDetail = err;
        if (err.response && err.response.data && err.response.data.error && err.response.data.error.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `${this.translationsFinance.claims_batches.errors.fetch_status_notifications_error} ${errDetail}`;
        this.$noty.error(errMsg);
      }
      this.doingNetworkRequest = false;
    },
    async export837File() {
      try {
        const { data } = await this.$store.dispatch('Financial/export837File', { id: this.claimsBatchID });
        const fileName = this.makeExport837Filename(this.details);
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translationsFinance.global.errors.export_837_file_generic;
        if (error.response && error.response.status === 404) {
          errorMsg = this.translationsFinance.global.errors.export_837_file_not_available;
        }
        this.$noty.error(errorMsg);
      }
    },
    makeExport837Filename(claimsBatch) {
      const clientName = `_${claimsBatch?.statement?.client_name}` || '';
      const payerName = `_${claimsBatch?.statement?.statement_configuration?.payer?.payer_name}` || '';
      const statementID = `_${claimsBatch?.statement?.id}` || '';
      const date = `_${moment(claimsBatch?.created_at).utc().format('YYYYMMDD')}` || '';

      let fileExtension = 'txt';
      if (claimsBatch?.statement?.statement_configuration?.clearing_house === 'waystar') {
        fileExtension = 'CLP';
      }

      return `SwordHealth_Claims${clientName}${payerName}${statementID}${date}.${fileExtension}`;
    },
    toggleDetailsExpanded(claim) {
      if (claim.transactions_page === 0) { // first time expanding the claim
        claim.transactions_page = 1;
        this.loadTransactions(claim);
      }
      if (claim.status_notifications_page === 0) {
        this.loadStatusNotifications(claim, 1);
      }

      this.claims.forEach(c => {
        if (c.id === claim.id) {
          c.details_expanded = !c.details_expanded;
        } else {
          c.details_expanded = false;
        }
      });
    },
  },
};
</script>

<style>
.btn-expand {
  min-width: 50px;
  max-width: 50px;
}

.btn-expand-col {
  width: 50px;
}

.expanded-row {
  background-color: rgba(0, 0, 0, .04);
}
</style>
